import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/GdzieKupic/About'
import Solutions from 'components/layout/Sections/Solutions'
import Distributors from 'components/layout/Sections/Pages/GdzieKupic/Distributors/Distributors'
import Map from 'components/layout/GoogleMap/Map'

import Navigation from 'components/layout/Navigation/index'

import useSearch from 'hooks/useSearch'
import useBreakpoint from 'hooks/useBreakpoint'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Gdzie kupić?',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Gdzie kupić?',
    },
  ],
}

const tableHeading = 'Wyszukaj dystrybutora'

const GdzieKupicPage: React.FC<PageProps> = ({ data }) => {
  const { lg } = useBreakpoint()

  const HOMEPAGE = data.wpPage.gdzieKupic
  const PAGE_SEO = data?.wpPage?.seo

  const SOLUTIONS_GALLERY = React.useMemo(
    () =>
      HOMEPAGE?.whereToBuyTiles?.map((item) => ({
        img: item.whereToBuyTilesImg.localFile.childImageSharp.gatsbyImageData,
        alt: 'tile',
        title: item.whereToBuyTilesTitle,
        link: `${item.whereToBuyTilesUrl}/`,
        subtitle: item.whereToBuyTilesDescription,
      })),
    []
  )

  const distributorsPins = HOMEPAGE.whereBuyDistributors.map((item) => ({
    lat: item.whereBuyDistributorsLat,
    lng: item.whereBuyDistributorsLng,
    companyName: item.whereBuyDistributorsCompanyName,
  }))

  const {
    onSearch,
    onMapPinClick,
    hideResetButton,
    resetButtonVisible,
    filteredItems,
  } = useSearch({
    distributors: HOMEPAGE.whereToBuyFindDistributor,
  })

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          HOMEPAGE?.whereBuyImg?.localFile?.childImageSharp.gatsbyImageData
        }
        imgDesktop={
          HOMEPAGE?.whereBuyImg?.localFile?.childImageSharp.gatsbyImageData
        }
        headingMobile={HOMEPAGE.whereBuyTitle}
        headingDesktop={HOMEPAGE.whereBuyTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={HOMEPAGE.whereBuyDescription} />
      <Map
        pins={distributorsPins}
        onMapPinClick={onMapPinClick}
        defaultZoom={5.7}
        defaultZoomLG={6.7}
        defaultCenter={{
          lat: 52.2,
          lng: 19.35,
        }}
      />
      {/* {filteredItems && (
        <Table
          heading={tableHeading}
          distributors={filteredItems}
          columns={[
            CompanyNameColumn,
            AddressColumn,
            PostalCodeColumn,
            CityColumn,
            RegionColumn,
            ExposureColumn,
            PhoneColumn,
            EmailColumn,
            WebsiteColumn,
            LinkColumn,
          ]}
          handleSearch={onSearch}
        />
      )} */}
      {filteredItems && (
        <Distributors
          heading={tableHeading}
          distributors={filteredItems}
          itemsPerPage={lg ? 16 : 6}
          resetButtonVisible={resetButtonVisible}
          handleSearch={onSearch}
          hideResetButton={hideResetButton}
        />
      )}
      <Solutions
        solutions={SOLUTIONS_GALLERY}
        uppercaseLabels
        backgroundMobile="primary100"
        backgroundDesktop="white"
      />
    </Layout>
  )
}

export default GdzieKupicPage

export const query = graphql`
  query GdzieKupicPage {
    wpPage(slug: { regex: "/gdzie-kupic/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      gdzieKupic {
        whereBuyDescription
        whereBuyTitle
        whereBuyImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        whereToBuyFindDistributor {
          whereToBuyFindDistributorAdress
          whereToBuyFindDistributorCompanyName
          whereToBuyFindDistributorEMail
          whereToBuyFindDistributorExposure
          whereToBuyFindDistributorMap
          whereToBuyFindDistributorPhoneNumber
          whereToBuyFindDistributorPlace
          whereToBuyFindDistributorPostCode
          whereToBuyFindDistributorProvince
          whereToBuyFindDistributorWebsite
        }
        whereBuyDistributors {
          whereBuyDistributorsLng
          whereBuyDistributorsLat
          whereBuyDistributorsCompanyName
        }
        whereToBuyTiles {
          whereToBuyTilesDescription
          whereToBuyTilesTitle
          whereToBuyTilesUrl
          whereToBuyTilesImg {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 950, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`
