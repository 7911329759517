import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'

import arrowRightIcon from 'assets/icons/arrow-right-white.svg'

import { ImageDataLike } from 'gatsby-plugin-image'
import useBreakpoint from 'hooks/useBreakpoint'

type GalleryItem = {
  img: ImageDataLike
  alt: string
  link: string
  title: string
  subtitle?: string
}

type Props = {
  gallery: GalleryItem[]
  uppercaseLabels?: boolean
  evenColumns?: boolean
}

const zoom = keyframes`
  0% {
    transform: scale(1)
  }

  100% {
    transform: scale(1.1)
  }

`

const GalleryWrapper = styled.div<{ even?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.media.xl.min} {
    display: grid;
    grid-template-columns: ${({ even }) =>
      even ? 'repeat(2,1fr)' : 'repeat(6,1fr)'};
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    overflow: auto;
    border-radius: 50px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 0;
  transition: 0.25s;

  &:before {
    content: '';
    position: absolute;
    display: block;
    bottom: 5px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #d4d4d4a3;
  }

  & ${Text} {
    padding: 0 20px;

    br {
      display: none;
    }
  }

  ${({ theme }) => theme.media.xl.min} {
    background-color: ${({ theme }) => theme.colors.primary100};
    height: 100%;
    width: min-content;
    padding: 10% 40px 20px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary200};
    }

    &:before {
      position: static;
      height: 0;
    }

    //Icon wrapper
    & > :last-child {
      align-self: flex-end;
      transform: translateX(25px);
    }

    & ${Text} {
      padding: 0;
      br {
        display: inline;
      }
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding: 10% 60px 20px 20px;
    //Icon wrapper
    & > :last-child {
      align-self: flex-end;
      transform: translateX(35px);
    }
    ${Text}:first-of-type {
      font-size: 24px;
    }

    ${Text} {
      width: max-content;
    }
  }
`

const GalleryItemWrapper = styled.div<{ even?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-height: 360px;

  & > div {
    width: 100%;
  }

  &:hover > div img {
    animation: ${zoom} 300ms ease-in-out forwards;
  }

  ${({ theme }) => theme.media.md.min} {
    max-height: 420px;
  }

  ${({ theme }) => theme.media.xl.max} {
    &:first-child {
      & > div:first-child {
        border-top-left-radius: 25%;
        border-top-right-radius: 25%;
      }
    }
  }

  ${({ theme }) => theme.media.xl.min} {
    // Gatsby Image wrapper
    & > div:first-child {
      height: 100%;
      width: 100%;
    }

    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 4;
      flex-direction: row;
    }

    :nth-child(2) {
      grid-area: 1 / 4 / 2 / 7;
      flex-direction: row;
    }

    :nth-child(3) {
      grid-area: 2 / 1 / 3 / 3;
      flex-direction: row-reverse;
    }

    :nth-child(4) {
      grid-area: 2 / 3 / 3 / 5;
      flex-direction: row-reverse;
    }

    :nth-child(5) {
      grid-area: 2 / 5 / 3 / 7;
      flex-direction: row-reverse;
    }

    ${({ even }) =>
      even &&
      css`
        grid-area: unset !important;
      `}
  }

  ${({ theme }) => theme.media.xl.min} {
    height: 240px;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    height: 270px;
  }
`

const IconWrapper = styled.div`
  margin-top: auto;
`

const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const TilesGallery1: React.FC<Props> = ({
  gallery,
  uppercaseLabels,
  evenColumns,
}) => {
  const { xl } = useBreakpoint()

  return (
    <GalleryWrapper even={evenColumns}>
      {gallery.map(({ img, alt, link, title, subtitle }, index) => (
        <GalleryItemWrapper even={evenColumns} key={`${title}-${index}`}>
          <LazyImage
            src={img}
            alt={alt}
            objectFit={!xl && title === 'Automatyka' ? 'contain' : 'cover'}
          />
          <InnerWrapper>
            <Text
              size={19}
              weight={500}
              align={xl ? 'left' : 'center'}
              themecolor="white"
              transform={uppercaseLabels ? 'uppercase' : 'none'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {subtitle && (
              <Text
                size={xl ? 12 : 16}
                weight={500}
                align={xl ? 'left' : 'center'}
                themecolor="white"
                transform={uppercaseLabels ? 'uppercase' : 'none'}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
            {xl && (
              <IconWrapper>
                <Icon src={arrowRightIcon} alt="arrow-right" />
              </IconWrapper>
            )}
          </InnerWrapper>
          <Link href={link} />
        </GalleryItemWrapper>
      ))}
    </GalleryWrapper>
  )
}

export default TilesGallery1
