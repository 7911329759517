import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import DistributorCard from 'components/layout/Sections/Pages/GdzieKupic/Distributors/DistributorCard'
import ReactPaginate from 'react-paginate'
import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import searchIcon from 'assets/icons/search.svg'
import arrowIcon from 'assets/icons/arrow-black.svg'

import useBreakpoint from 'hooks/useBreakpoint'

import type { Distributor } from 'types/table'

type DistributorsProps = {
  heading: string
  distributors: Distributor[]
  itemsPerPage: number
  resetButtonVisible: boolean
  handleSearch: (value: string) => void
  hideResetButton: () => void
}

const Section = styled.section`
  position: relative;
  width: 100%;
  margin-top: 40px;
  padding: 40px 0;
  background-color: #fbfbfc;
`

const StyledContainer = styled(Container)`
  padding-left: 2rem;
  padding-right: 2rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 310px;
  margin: 0 auto 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: unset;
  }
`
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 20px 2px #a5a5a529;
  border-radius: 10px;
  height: 42px;

  ${({ theme }) => theme.media.lg.min} {
    width: 35%;
    max-width: 450px;
  }
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 45px;
  outline: none;
  border: none;
  border-radius: inherit;
  font-size: 15px;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
`

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 35px;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1fr);
    gap: 20px;
  }

  ${({ theme }) => theme.media.xl.min} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const ResetButton = styled.button`
  margin-top: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary600};
  text-transform: uppercase;
`

const StyledReactPaginationWrapper = styled.div`
  position: absolute;
  ul {
    position: relative;
    width: 100%;
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    list-style: none;
  }

  li {
    width: 35px;
    height: 35px;
    margin: 0 5px;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary600};
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.colors.primary600};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary600};
      color: ${({ theme }) => theme.colors.white};
    }

    ${({ theme }) => theme.media.s.min} {
      width: 45px;
      height: 45px;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  li.selected {
    background-color: ${({ theme }) => theme.colors.primary600};
    color: ${({ theme }) => theme.colors.white};
    cursor: default;
    pointer-events: none;
  }

  li:first-child {
    display: none;
  }

  li:last-child {
    position: absolute;
    right: -15px;
    width: 100px;
    transform: translateX(100%);
    border: none;
    background-color: transparent;
    z-index: 1;
  }

  li:last-child.disabled {
    cursor: default;
  }

  ${({ theme }) => theme.media.lg.min} {
    position: unset;
    ul {
      display: flex;
    }
  }
`

const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px auto 0;
  max-width: 310px;

  ${({ theme }) => theme.media.lg.min} {
    max-width: unset;
    justify-content: flex-end;
    margin: 30px auto 0;
  }
`

const PaginationCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary600};
`

const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const PaginationButton = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;

  :first-of-type {
    ${ArrowIcon} {
      margin-right: 5px;
      transform: rotate(180deg);
    }
  }

  :last-of-type {
    ${ArrowIcon} {
      margin-left: 5px;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${({ theme }) => theme.media.lg.min} {
    margin-left: 10px;
    :first-of-type {
      ${ArrowIcon} {
        transform: none;
      }
    }
  }
`

const ScrollAnchor = styled.span`
  position: absolute;
  left: 0;
  top: -50px;

  ${({ theme }) => theme.media.lg.min} {
    top: -180px;
  }
`

const Distributors: React.FC<DistributorsProps> = ({
  heading,
  distributors,
  itemsPerPage,
  resetButtonVisible,
  handleSearch,
  hideResetButton,
}) => {
  const [currentItems, setCurrentItems] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(distributors.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(distributors.length / itemsPerPage))
  }, [itemOffset, distributors])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % distributors.length

    setItemOffset(newOffset)
  }

  const handlePrev = () => {
    setItemOffset(itemOffset - itemsPerPage)
  }

  const handleNext = () => {
    setItemOffset(itemOffset + itemsPerPage)
  }

  const { lg } = useBreakpoint()

  return (
    <Section>
      <ScrollAnchor id="wyszukaj-dystrybutora" />
      <StyledContainer>
        <Header>
          <Heading
            as="h2"
            size={28}
            weight={600}
            themecolor="black"
            margin={'15px'}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <InputWrapper>
            <IconWrapper>
              <Icon src={searchIcon} size={22} alt="search" />
            </IconWrapper>
            <SearchInput
              type="text"
              placeholder="Wyszukaj województwo"
              onChange={(e) => handleSearch(e.target.value.toLowerCase())}
            />
          </InputWrapper>
        </Header>
        <Cards>
          {currentItems.map((distributor, index) => (
            <DistributorCard
              key={`distributor-card-${index}`}
              distributor={distributor}
            />
          ))}
        </Cards>
        {resetButtonVisible && (
          <ResetButton type="button" onClick={() => hideResetButton()}>
            Pokaż wszystkie
          </ResetButton>
        )}
        <PaginationControls>
          <StyledReactPaginationWrapper>
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel=""
              nextLabel=""
              renderOnZeroPageCount={() => null}
            />
          </StyledReactPaginationWrapper>
          {!lg && (
            <PaginationButton
              type="button"
              onClick={handlePrev}
              disabled={itemOffset === 0}
            >
              <ArrowIcon>
                <Icon src={arrowIcon} size={15} alt="arrow-left" />
              </ArrowIcon>
              Poprzednia
            </PaginationButton>
          )}
          {!lg && (
            <PaginationCount>
              <Text
                weight={600}
                size={16}
                themecolor="primary600"
                dangerouslySetInnerHTML={{
                  __html: `${
                    (itemOffset + itemsPerPage) / itemsPerPage
                  } z ${pageCount}`,
                }}
              />
            </PaginationCount>
          )}
          <PaginationButton
            type="button"
            onClick={() => {
              if (lg) {
                handlePageClick({ selected: 2 })
              } else {
                handleNext()
              }
            }}
            disabled={itemOffset >= (pageCount - 1) * itemsPerPage}
          >
            Następna
            <ArrowIcon>
              <Icon src={arrowIcon} size={15} alt="arrow-right" />
            </ArrowIcon>
          </PaginationButton>
        </PaginationControls>
      </StyledContainer>
    </Section>
  )
}

export default Distributors
