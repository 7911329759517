export const convertPolishLetters = (string: string) => {
  let returnString = string

  returnString = returnString.replace(/ą/g, 'a')
  returnString = returnString.replace(/ć/g, 'c')
  returnString = returnString.replace(/ę/g, 'e')
  returnString = returnString.replace(/ł/g, 'l')
  returnString = returnString.replace(/ń/g, 'n')
  returnString = returnString.replace(/ó/g, 'o')
  returnString = returnString.replace(/ś/g, 's')
  returnString = returnString.replace(/ź/g, 'z')
  returnString = returnString.replace(/ż/g, 'z')

  return returnString
}
