import React from 'react'
import styled, { css } from 'styled-components'

import TilesGallery1 from 'components/layout/TilesGallery/TilesGallery1'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

import patternImg1 from 'assets/images/pattern-1.png'
import patternImg2 from 'assets/images/pattern-2.png'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'
import useLocation from 'hooks/useLocation'

import type { Colors } from 'types/theme'

type Solution = {
  img: ImageDataLike
  alt: string
  title: string
  subtitle?: string
}

type Props = {
  heading?: string
  solutions: Solution[]
  uppercaseLabels?: boolean
  evenColumns?: boolean
  backgroundMobile: keyof Colors
  backgroundDesktop: keyof Colors
}

const Section = styled.section<{
  backgroundMobile: keyof Colors
  backgroundDesktop: keyof Colors
}>`
  position: relative;
  width: 100%;
  ${({ theme, backgroundMobile }) =>
    backgroundMobile &&
    css`
      background-color: ${theme.colors[backgroundMobile]};
    `}
  padding: 0 0 60px 0;

  ${({ theme }) => theme.media.xl.min} {
    ${({ theme, backgroundDesktop }) =>
      backgroundDesktop &&
      css`
        background-color: ${theme.colors[backgroundDesktop]};
      `}
    padding: 60px 0 90px 0;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  padding: 20px 0 0 0;

  & > ${Heading} {
    position: relative;
    padding: 0 10px;
    z-index: 2;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.width} + (${theme.container.padding} * 2))`};
    padding: 20px ${({ theme }) => theme.container.paddingMD} 0;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.widthXL} + (${theme.container.padding} * 2))`};
  }
`

const PatternWrapper1 = styled.div`
  position: absolute;
  top: 0;
  left: -20%;
  z-index: 1;

  ${({ theme }) => theme.media.xxl.min} {
    left: -7.5%;
  }
`

const PatternWrapper2 = styled.div`
  position: absolute;
  bottom: -1%;
  right: -1%;
  width: 300px;
  z-index: 1;
`

const Solutions: React.FC<Props> = ({
  heading,
  solutions,
  uppercaseLabels,
  evenColumns,
  backgroundMobile,
  backgroundDesktop,
}) => {
  const { xl } = useBreakpoint()
  const pathname = useLocation()

  return (
    <Section
      id="solutions"
      title={heading ? heading.replace(/<br\/> /g, '') : ''}
      backgroundMobile={backgroundMobile}
      backgroundDesktop={backgroundDesktop}
    >
      <StyledContainer>
        {heading && (
          <Heading
            as="h2"
            size={28}
            weight={600}
            align="center"
            themecolor={xl ? 'primary' : 'white'}
            margin="50px"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        <TilesGallery1
          gallery={solutions}
          uppercaseLabels={uppercaseLabels}
          evenColumns={evenColumns}
        />
      </StyledContainer>
      {xl && pathname !== '/gdzie-kupic' && (
        <>
          <PatternWrapper1>
            <img src={patternImg1} alt="pattern" />
          </PatternWrapper1>
          <PatternWrapper2>
            <img src={patternImg2} alt="pattern" />
          </PatternWrapper2>
        </>
      )}
    </Section>
  )
}

export default Solutions
