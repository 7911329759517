import { useState, useEffect } from 'react'

import type { Distributor } from 'types/table'

import { convertPolishLetters } from 'utils/convertPolishLetters'

type Props = {
  distributors?: Distributor[]
}

const useSearch = ({ distributors }: Props) => {
  const [matchingItems, setMatchingItems] = useState<Distributor[] | undefined>(
    distributors
  )
  const [searchValue, setSearchValue] = useState('')
  const [resetButtonVisible, setResetButtonVisible] = useState(false)

  const handleSearchInputChange = (value: string) => {
    const convertedValue = convertPolishLetters(value)

    setSearchValue(convertedValue)
  }

  const handleShowCompanyInfo = (companyName: string) => {
    if (!companyName) return
    const filteredByCompanyName = distributors?.filter((item) => {
      if (item.whereToBuyFindDistributorCompanyName) {
        return convertPolishLetters(
          item.whereToBuyFindDistributorCompanyName?.toLowerCase()
        ).includes(convertPolishLetters(companyName.toLowerCase()))
      }
      return null
    })

    setMatchingItems(
      filteredByCompanyName?.length === 0 ? distributors : filteredByCompanyName
    )
    setResetButtonVisible(true)
  }

  const handleFindMatchingItems = () => {
    if (!searchValue) {
      setMatchingItems(distributors)
      return
    }

    const filteredByRegion = matchingItems?.filter((item) => {
      if (item.whereToBuyFindDistributorProvince) {
        return convertPolishLetters(
          item.whereToBuyFindDistributorProvince?.toLowerCase()
        ).includes(searchValue)
      }
      return null
    })

    // if (!filteredByRegion || filteredByRegion.length < 1) {
    //   const filteredByCity = matchingItems?.filter((item) => {
    //     if (item.whereToBuyFindDistributorPlace) {
    //       return convertPolishLetters(
    //         item.whereToBuyFindDistributorPlace.toLowerCase()
    //       ).includes(searchValue)
    //     }
    //     return null
    //   })

    //   setMatchingItems(filteredByCity)
    //   return
    // }

    setMatchingItems(filteredByRegion)
  }

  const hideResetButton = () => {
    setResetButtonVisible(false)
    setMatchingItems(distributors)
  }

  useEffect(() => {
    handleFindMatchingItems()
  }, [searchValue, distributors])

  return {
    onSearch: handleSearchInputChange,
    onMapPinClick: handleShowCompanyInfo,
    resetButtonVisible,
    hideResetButton,
    filteredItems: matchingItems,
  }
}

export default useSearch
