import React from 'react'
import styled, { css } from 'styled-components'

import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import addressIcon from 'assets/icons/address.svg'
import phoneIcon from 'assets/icons/phone-2.svg'
import websiteIcon from 'assets/icons/website.svg'
import locationIcon from 'assets/icons/location.svg'

import type { Distributor } from 'types/table'

type Props = {
  distributor: Distributor
}

const Card = styled.div`
  width: 100%;
  max-width: 310px;
  padding: 20px 37px 20px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 20px 2px #a5a5a529;
  border-radius: 25px;
  margin-bottom: 20px;

  ${({ theme }) => theme.media.lg.min} {
    height: 100%;
    min-width: 260px;
    max-width: unset;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 100%;
    min-width: 310px;
    max-width: unset;
  }
`

const Header = styled.div`
  border-bottom: 2px solid #aa96843a;
  margin-bottom: 15px;
  padding-left: 10px;
`

const InfoSingle = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  padding-left: 10px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  margin-right: 5px;
`

const TextLink = styled(Text)<{ hoverable: boolean }>`
  transition: 0.15s ease-in-out;

  ${({ hoverable }) =>
    hoverable &&
    css`
      &:hover {
        color: ${({ theme }) => theme.colors.primary600};
      }
    `}
`

const Content = styled.div`
  padding-left: 40px;
`

const NoInfoIndicator = styled.div`
  width: 25px;
  height: 2px;
  margin-top: 20px;
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.black100};
`

const DistributorCard: React.FC<Props> = ({ distributor }) => {
  const { lg } = useBreakpoint()

  return (
    <Card>
      <Header>
        <Heading
          as="h6"
          size={lg ? 20 : 24}
          themecolor="primary600"
          margin="10px"
          dangerouslySetInnerHTML={{
            __html: distributor.whereToBuyFindDistributorCompanyName,
          }}
        />
      </Header>
      {/* Adres */}
      <InfoSingle>
        <InfoHeader>
          <IconWrapper>
            <Icon src={addressIcon} size={20} alt="address" />
          </IconWrapper>
          <Text
            size={lg ? 16 : 18}
            weight={600}
            margin="0"
            line={1}
            themecolor="black"
            dangerouslySetInnerHTML={{ __html: 'Adres' }}
          />
        </InfoHeader>
        {distributor.whereToBuyFindDistributorAdress &&
        distributor.whereToBuyFindDistributorPostCode &&
        distributor.whereToBuyFindDistributorPlace ? (
          <Content>
            <Text
              size={lg ? 14 : 16}
              weight={500}
              margin="5px"
              themecolor="black"
              dangerouslySetInnerHTML={{
                __html: distributor.whereToBuyFindDistributorAdress,
              }}
            />
            <Text
              size={lg ? 14 : 16}
              weight={500}
              themecolor="black"
              dangerouslySetInnerHTML={{
                __html: `${distributor.whereToBuyFindDistributorPostCode} ${distributor.whereToBuyFindDistributorPlace}`,
              }}
            />
          </Content>
        ) : (
          <NoInfoIndicator />
        )}
      </InfoSingle>
      {/* Telefon */}
      <InfoSingle>
        <InfoHeader>
          <IconWrapper>
            <Icon src={phoneIcon} size={16} alt="phone" />
          </IconWrapper>
          <Text
            size={lg ? 16 : 18}
            weight={600}
            margin="0"
            line={1}
            themecolor="black"
            dangerouslySetInnerHTML={{ __html: 'Telefon' }}
          />
        </InfoHeader>
        {distributor.whereToBuyFindDistributorPhoneNumber ? (
          <Content>
            <TextLink
              as="a"
              hoverable={!!distributor.whereToBuyFindDistributorPhoneNumber}
              href={`tel: ${distributor.whereToBuyFindDistributorPhoneNumber}`}
              size={lg ? 14 : 16}
              weight={500}
              margin="5px"
              themecolor="black"
              dangerouslySetInnerHTML={{
                __html: distributor.whereToBuyFindDistributorPhoneNumber,
              }}
            />
          </Content>
        ) : (
          <NoInfoIndicator />
        )}
      </InfoSingle>
      {/* Website */}
      <InfoSingle>
        <InfoHeader>
          <IconWrapper>
            <Icon src={websiteIcon} size={16} alt="website" />
          </IconWrapper>
          <Text
            size={lg ? 16 : 18}
            weight={600}
            margin="0"
            line={1}
            themecolor="black"
            dangerouslySetInnerHTML={{ __html: 'Adres www' }}
          />
        </InfoHeader>
        {distributor.whereToBuyFindDistributorWebsite ? (
          <Content>
            <TextLink
              as="a"
              hoverable={!!distributor.whereToBuyFindDistributorWebsite}
              href={distributor.whereToBuyFindDistributorWebsite}
              target="_blank"
              rel="noopener noreferrer nofollow"
              size={lg ? 14 : 16}
              weight={500}
              margin="5px"
              decoration="underline"
              themecolor="black"
              dangerouslySetInnerHTML={{
                __html: distributor.whereToBuyFindDistributorWebsite,
              }}
            />
          </Content>
        ) : (
          <NoInfoIndicator />
        )}
      </InfoSingle>
      {/* Map link */}
      {distributor.whereToBuyFindDistributorMap && (
        <InfoSingle>
          <InfoHeader>
            <IconWrapper>
              <Icon src={locationIcon} size={16} alt="location" />
            </IconWrapper>
            <TextLink
              as="a"
              hoverable={!!distributor.whereToBuyFindDistributorMap}
              href={distributor.whereToBuyFindDistributorMap}
              target="_blank"
              rel="noopener noreferrer nofollow"
              size={lg ? 16 : 18}
              weight={600}
              margin="0"
              line={1}
              decoration="underline"
              themecolor="black"
              dangerouslySetInnerHTML={{ __html: 'Link do mapy' }}
            />
          </InfoHeader>
        </InfoSingle>
      )}
    </Card>
  )
}

export default DistributorCard
